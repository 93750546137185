import React from 'react'
import { graphql, PageProps } from 'gatsby'
import styled from 'styled-components'
import media from 'styles/media'

import { Container } from 'components/atoms/Container'
import { Layout } from 'views/Layout'
import { Seo } from 'components/atoms/Seo'
import { LeftContentHero } from 'components/modules/LeftContentHero'
import { Breadcrumbs } from 'components/modules/Breadcrumbs'
// import { BuildingLogs } from 'components/organisms/BuildingLogs'
// import { Contact } from 'components/organisms/Contact'
import { WorkProgress } from 'components/organisms/InvestmentDescription/WorkProgress'

const BreadcrumbsContainer = styled(Container)`
  max-width: 1216px;

  ${media.sm.max} {
    padding: 0px;
  }
`

const WpisDziennikaBudowyPage: React.FC<
  PageProps<Queries.WpisDziennikaBudowyPageQuery>
> = ({ data }) => {
  const PAGE = data.wpLogSingle?.buildingLogSingle
  const PAGE_SEO = data.wpLogSingle?.seo!

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  // const LOGS =
  //   PAGE.buildinglogSinglePost?.map((log) => ({
  //     date: log?.buildinglogSinglePostDate!,
  //     title: log?.buildinglogSinglePostTitle!,
  //     description: log?.buildinglogSinglePostDescription!,
  //     images:
  //       log?.buildinglogSinglePostGallery?.map((image) => ({
  //         src: image?.buildinglogSinglePostGalleryImg?.localFile
  //           ?.childImageSharp?.gatsbyImageData!,
  //         alt: image?.buildinglogSinglePostGalleryImg?.altText!,
  //       })) || [],
  //   })) || []

  const WorkProgressData = {
    heading:
      data.wpInvestmentsSingle?.investment?.investmentWorkProgressNaglowek!,
    stages:
      data.wpInvestmentsSingle?.investment?.investmentWorkProgressStages?.map(
        (stage) => ({
          stageName: stage?.investmentWorkProgressStagesName!,
          timeline: stage?.investmentWorkProgressStagesTimeline?.map(
            (item) => ({
              lable: item?.investmentWorkProgressStagesTimelineText!,
              isDone:
                item?.investmentWorkProgressStagesTimelineStatus === 'isDone'!,
              inProgress:
                item?.investmentWorkProgressStagesTimelineStatus ===
                'inProgres'!,
            })
          )!,
          images: stage?.investmentWorkProgressStagesGallery?.map((item) => ({
            image: {
              src: item?.investmentWorkProgressStagesGalleryImg?.localFile
                ?.childImageSharp?.gatsbyImageData!,
              alt: item?.investmentWorkProgressStagesGalleryImg?.altText!,
            }!,
            date: item?.investmentWorkProgressStagesGalleryDate!,
          }))!,
        })
      ) || [],
  }

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <LeftContentHero
        image={{
          src: PAGE.buildinglogSingleHeroImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE.buildinglogSingleHeroImg?.altText!,
        }}
        lable={PAGE.buildinglogSingleHeroTitle!}
        text={PAGE.buildinglogSingleHeroCommunication!}
        textTag="h2"
        headingTag="h1"
      />
      <BreadcrumbsContainer margin={[0, 'auto']} padding={[0, 40]}>
        <Breadcrumbs
          crumbs={[
            { name: 'Dziennik budowy', to: '/dziennik-budowy' },
            { name: data.wpLogSingle.title!! },
          ]}
        />
      </BreadcrumbsContainer>

      {/* <BuildingLogs logs={LOGS} /> */}

      {data.wpInvestmentsSingle && (
        <WorkProgress
          heading={WorkProgressData.heading}
          stages={WorkProgressData.stages}
        />
      )}

      {/* <Contact
        heading={PAGE.buildinglogSingleFormTitle!}
        formTitle={PAGE.buildinglogSingleFormFormTitle!}
        image={{
          src: PAGE.buildinglogSingleFormImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE.buildinglogSingleFormImg?.altText!,
        }}
      /> */}
    </Layout>
  )
}

export default WpisDziennikaBudowyPage

export const query = graphql`
  query WpisDziennikaBudowyPage($slug: String, $investment: String) {
    wpLogSingle(slug: { eq: $slug }) {
      title
      buildingLogSingle {
        buildinglogSingleFormTitle
        buildinglogSingleFormFormTitle
        buildinglogSingleFormImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
        buildinglogSingleHeroCommunication
        buildinglogSingleHeroTitle
        buildinglogSingleHeroImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        buildinglogSinglePost {
          buildinglogSinglePostDate
          buildinglogSinglePostDescription
          buildinglogSinglePostTitle
          buildinglogSinglePostGallery {
            buildinglogSinglePostGalleryImg {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1200)
                }
              }
            }
          }
        }
      }
      seo {
        ...WpSEO
      }
    }
    wpInvestmentsSingle(slug: { eq: $investment }) {
      investment {
        investmentWorkProgressNaglowek
        investmentWorkProgressStages {
          investmentWorkProgressStagesName
          investmentWorkProgressStagesTimeline {
            investmentWorkProgressStagesTimelineStatus
            investmentWorkProgressStagesTimelineText
          }
          investmentWorkProgressStagesGallery {
            investmentWorkProgressStagesGalleryDate
            investmentWorkProgressStagesGalleryImg {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1200)
                }
              }
            }
          }
        }
      }
    }
  }
`
